@import "../../../../../assets/scss/common";

.icon {
  &.icon-position-left {
    margin-right: $spacer / 2;
  }

  &.icon-position-right {
    margin-left: $spacer / 2;
  }
}
