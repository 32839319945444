@import "../../assets/scss/common";

.notifications {
  margin-top: $spacer;

  .alert {
    margin: $spacer / 2 $grid-gutter-width;
    padding: $spacer;

    &.alert-success {
      color: $teal-900;
      background-color: $teal-200;
      border-color: $teal-300;
    }

    &.alert-error {
      color: $red-900;
      background-color: $red-200;
      border-color: $red-300;
    }

    .dismiss-message {
      padding: $spacer;
      position: absolute;
      top: 0;
      right: $spacer / 2;
    }
  }
}
