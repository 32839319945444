@import "../../assets/scss/common";

.spinner {
  position: fixed;
  z-index: 99999;
  height: 2em;
  width: 5em;
  overflow: visible;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.spinner:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
}

.spinner > div {
  width: 20px;
  height: 20px;
  background-color: $white;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: spinner 1.5s infinite ease-in-out both;
  animation: spinner 1.5s infinite ease-in-out both;
}

.spinner .bounce {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}

.spinner .bounce-next {
  -webkit-animation-delay: -0.15s;
  animation-delay: -0.15s;
}

@-webkit-keyframes spinner {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes spinner {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
