.container {
  font-size: 0.875rem;
}

.icon-container {
  flex: 0 0 auto;
  width: 12%;
  padding: 0 0.5rem 0 0;
}

.code-iata-container {
  flex: 0 0 auto;
  width: 13%;
}

.dash-container {
  flex: 0 0 auto;
  width: 1%;
  padding: 0 0.5rem;
}

.name-container {
  flex: 0 0 auto;
  width: 50%;
  padding: 0 0.5rem;
}

.code-icao-container {
  flex: 0 0 auto;
  width: 23%;
  padding: 0 0 0 0.5rem;
}
