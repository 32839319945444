@import "../../../assets/scss/common";

.step {
  &-circle {
    display: block;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border-width: 6px;
    border-style: solid;
    border-color: $primary;

    &-inactive {
      border-color: $gray-light;
    }
  }

  &-line {
    height: 4px !important;
    width: 100%;
    position: relative;
    top: -4px;
    background-color: $primary;
    opacity: 0.5;

    &-inactive {
      background-color: $gray-light;
    }
  }

  &-text-inactive {
    opacity: 0.4;
  }
}
