@import "../../../assets/scss/common";

$prefix: "overlapped";

.#{$prefix}-avatars-1 {
  &.#{$prefix}-parent {
    display: grid;
    position: relative;
    justify-items: center;
  }
}

.#{$prefix}-avatars-2 {
  &.#{$prefix}-parent {
    display: grid;
    position: relative;
    justify-items: center;
    grid-template-columns: repeat(5, 1fr);
  }

  &.#{$prefix}-child-0 {
    grid-column: 1 / span 4;
    grid-row: 1;
    z-index: 1;
  }

  &.#{$prefix}-child-1 {
    grid-column: 2 / -1;
    grid-row: 1;
  }
}

.#{$prefix}-avatars-3 {
  &.#{$prefix}-parent {
    display: grid;
    position: relative;
    justify-items: center;
    grid-template-columns: repeat(9, 1fr);
  }

  &.#{$prefix}-child-0 {
    grid-column: 1 / span 6;
    grid-row: 1;
    z-index: 2;
  }

  &.#{$prefix}-child-1 {
    grid-column: 1 / -1;
    grid-row: 1;
    z-index: 1;
  }

  &.#{$prefix}-child-2 {
    grid-column: 4 / -1;
    grid-row: 1;
  }
}

.operating-date {
  margin-left: 27%;
}

.transfer-duration-empty-container {
  height: 4rem;
}

.transfer-duration-container {
  height: 4.75rem;
}

.no-transfer-duration-container {
  height: 3.5rem;
}

.logo-container {
  height: 7.5rem;
}

.trip {
  &-empty-dot {
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 50%;
    border: $primary 0.125rem solid;
  }

  &-full-dot {
    width: 0.75rem;
    height: 0.75rem;
    border-radius: 50%;
    background-color: $primary;
  }

  &-transfer-dot {
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background-color: $primary;
  }

  &-expanded-solid-line {
    width: 0.125rem;
    height: 4.5rem;
    background-color: $primary;
  }

  &-collapsed-solid-line {
    width: 0.125rem;
    height: calc(50% - 1rem);
    background-color: $primary;
  }

  &-collapsed-short-line {
    width: 0.125rem;
    height: 0.5rem;
    background-color: $primary;
  }

  &-dashed-line {
    width: 0.125rem;
    height: 5.1875rem;
    border: $magenta 0.0625rem dashed;
  }
}
