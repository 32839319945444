@import "../../assets/scss/common";

#wrapper {
  overflow-x: hidden;
}

#sidebar-wrapper {
  background: $blue-dark;
  min-height: 100vh;
  margin-left: $spacer * -16;
  transition: $transition-base;

  & > .navbar {
    min-height: $spacer * 5;
  }

  .sidebar-items {
    h5 {
      color: $white;
      margin-top: $spacer * 2;
      padding-left: $spacer;
      text-transform: uppercase;
    }

    .list-group {
      .list-group-item {
        background-color: $blue-dark;
        color: $white;
        border: none;
        border-radius: 0;
        padding: $spacer / 3 0 $spacer / 3 $spacer * 3;

        &.sub-item {
          padding-left: $spacer * 5;
          &:hover,
          &.active {
            background: $blue-dark-700;
          }
        }
      }
    }
  }
}

#page-content-wrapper {
  min-width: 100vw;

  & > .navbar {
    min-height: $spacer * 5;
  }
}

#wrapper.toggled #sidebar-wrapper {
  margin-left: 0;
}

@include media-breakpoint-up(md) {
  #sidebar-wrapper {
    margin-left: 0;
  }

  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }

  #wrapper.toggled #sidebar-wrapper {
    margin-left: $spacer * -16;
  }
}

.main-content {
  padding: $spacer * 1.5;
}
