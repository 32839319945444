.td-icon-add {
  padding: 6px 4px !important;
}

.td-add-booking {
  overflow: visible !important;
  position: relative;
}

.td-select-tour-operator {
  position: absolute;
  z-index: 10000 !important;
  top: -8.25rem;
  left: -6.25rem;
  width: 350px;
}

.tf-add-booking {
  z-index: 1;
  position: absolute;
  bottom: 7px;
}
