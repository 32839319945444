@import "../../../assets/scss/common";

.segments {
  &-header {
    height: 3.125rem;

    b {
      font-size: 1.25rem;
    }
  }

  &-operating-day {
    height: 40px;
    background-color: $gray-200;
  }

  &-hr {
    background-color: $gray-600;
  }
}
