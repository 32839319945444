@import "../../../assets/scss/common";

.time-field.has-floating-label ~ label.floating,
.image-field.has-floating-label ~ label.floating,
.date-field.has-floating-label ~ label.floating,
.date-range-field.has-floating-label ~ label.floating,
.select-field.has-floating-label ~ label.floating,
input.has-floating-label ~ label.floating,
textarea.has-floating-label ~ label.floating {
  top: 0.25rem;
  left: 0.5rem;
  font-size: 0.6875rem;
  opacity: 1;
  color: $secondary;
  padding: 0 0.25rem;
  max-width: calc(100% - 1.25rem);
}

label.floating {
  position: absolute;
  pointer-events: none;
  left: 1rem;
  top: 0.75rem;
  transition: 0.2s ease all;
  color: $input-placeholder-color;
  max-width: calc(100% - 2rem);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.form-field {
  padding: 1rem 0.75rem 0.25rem 0.5625rem;
}

.field-control {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.ant-picker {
  padding: 0.9375rem 0.625rem 0.25rem;

  &-input {
    input {
      font-size: 1rem;
    }
  }

  &-suffix {
    margin-top: 0.5rem;
  }
}

.date-range-field {
  .ant-picker-suffix {
    margin-top: -0.5rem;
  }

  .ant-picker-clear {
    top: 56%;
  }
}

.date-field {
  .ant-picker-suffix {
    margin-top: -0.5rem;
  }

  .ant-picker-clear {
    margin-top: -0.25rem;
  }
}

.time-field {
  .ant-picker-suffix {
    margin-top: -0.125rem;
  }

  .ant-picker-clear {
    margin-top: -0.25rem;
  }
}

.currency-field {
  min-width: 5.875rem;
}

.file-field {
  label {
    display: block;
    width: 100%;
    background-color: $white;
    border: 1px $primary dashed;
    text-align: center;
    cursor: pointer;
  }
}

.input-group-text {
  font-size: 1.125rem;
  height: 100%;
  background-color: $white;
  color: $magenta;
  padding: 0.375rem;
}
