@import "../../assets/scss/common";

.th {
  color: $white !important;
  text-transform: uppercase;
}

.th-flight-details {
  @extend .th;
  background-color: $gray;
}

.th-airlines {
  @extend .th;
  background-color: $magenta;
}

.th-sub-airlines {
  background-color: $magenta-light;
}

.th-tour-operators {
  @extend .th;
  background-color: $teal;
}

.th-sub-tour-operators {
  background-color: $teal-light;
}

.th-tickets {
  @extend .th;
  background-color: $orange;
}

.th-sub-tickets {
  background-color: $orange-light;
}

.tf-white {
  background-color: $white !important;
}

.t-gray-light {
  background-color: $gray-200;
}

.th-delta {
  overflow: visible !important;
}

.th-delta-content {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 78px;
  height: 84px;
  color: $orange;
  background-color: $orange-light;
  font-size: 4rem;
}

.td-delta {
  width: 80px;
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  height: 40px;
  line-height: 40px;
}

.td-icon-add {
  padding: 6px 4px !important;
}
