@import "../../assets/scss/common";

$size: 22px;

.checkbox-container {
  display: inline-flex;
  flex-direction: column;

  &.align-left {
    align-items: flex-start;
  }

  &.align-center {
    align-items: center;
  }

  &.align-right {
    align-items: flex-end;
  }

  .label {
    font-size: $font-size-sm;
    margin-bottom: $spacer / 2;
    cursor: pointer;
  }

  &.disabled .label {
    cursor: default;
  }

  .checkbox {
    position: relative;
    width: $size;
    height: $size;

    & label {
      background-color: #fff;
      border: 2px solid $primary;
      border-radius: 50%;
      height: $size;
      left: 0;
      position: absolute;
      top: 0;
      width: $size;
      &:after {
        border: 3px solid $primary;
        border-top: none;
        border-right: none;
        content: "";
        height: 6px;
        left: 4px;
        opacity: 0;
        position: absolute;
        top: 5px;
        transform: rotate(-45deg);
        width: 10px;
      }
    }

    & input[type="checkbox"] {
      visibility: hidden;

      & + label {
        cursor: pointer;
      }

      &:checked + label {
        background-color: #e1f3f6; /* TODO: fix when palette gets delivered */
        border-color: $primary;
        &:after {
          opacity: 1;
        }
      }

      &:disabled + label {
        opacity: 0.2;
        cursor: default;
      }
    }
  }
}
