@import "../../assets/scss/common";

.icon {
  font-size: $font-size-base * 1.3;

  &.icon-sm {
    font-size: $font-size-base;
  }

  &.icon-lg {
    font-size: $font-size-base * 1.6;
  }

  &.icon-xl {
    font-size: $font-size-base * 2.2;
  }
}

.icon-link {
  text-decoration: none;
}
