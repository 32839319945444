@import "../../assets/scss/common";

.btn {
  line-height: 1;

  &.has-icon {
    &.btn-sm {
      padding: 0.185rem $spacer / 1.5;
      .icon {
        margin-right: $spacer / 2;
      }
    }

    &.btn-md {
      padding: $spacer / 4 $spacer / 1.25;
      .icon {
        margin-right: $spacer / 2;
      }
    }

    &.btn-lg {
      padding: $spacer / 3 $spacer * 1;
      .icon {
        margin-right: $spacer / 1.5;
      }
    }
  }

  .btn-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .icon {
    @include transition($btn-transition);
  }

  &:hover {
    .icon {
      color: $white !important;
      @include transition($btn-transition);
    }
  }
}
