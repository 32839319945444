@import "../../assets/scss/common";

.duration {
  &-container {
    position: relative;

    p {
      position: absolute;
      top: 0.75rem;
    }
  }

  &-leg {
    p {
      right: 2rem;
      background-color: $card-bg;
    }
  }

  &-layover {
    p {
      background-color: $white;
      left: 0;
      right: 0;
      text-align: center;
      margin-left: auto;
      margin-right: auto;
      width: fit-content;
    }
  }
}
