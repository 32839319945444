@import "../../../../assets/scss/common";

.text-circle {
  border-style: solid;
  border-width: 0.0625rem;
  border-color: $primary;
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 50%;
  display: inline-block;
  text-align: center;
  padding-top: 0.375rem;
}
