@import "../../assets/scss/common";

.avatar {
  border-radius: 50%;
  color: $blue-dark;
  border: 0.09375rem solid $primary;
  padding: 0.25rem 0;
  cursor: default;
  text-align: center;
  background-color: $white;
  margin-top: -0.3125rem;

  img {
    border-radius: 50%;
    top: 1px;
    left: 1px;
    position: absolute;
  }

  span {
    display: inline-block;
    position: absolute;
    top: 17%;
    left: 3%;
  }
}
