@import "../../assets/scss/common";

.field-detail {
  height: 2.1875rem;
  padding-top: 0.4375rem;

  &.multiLineText {
    height: 100%;
    padding-bottom: 0.4375rem;
  }
}

.field-detail-control {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.field-detail-container {
  .input-group-prepend,
  .input-group-append {
    span {
      font-size: 1.5rem;
      margin-left: 0.5rem;
    }
  }
}
