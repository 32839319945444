@import "../../assets/scss/common";

.popover-container {
  border: 3px solid $teal-light;
  box-shadow: rgba(0, 0, 0, 0.18) 0px 2px 4px;
}

.header-container {
  border-bottom: 3px solid $teal-light;
}
